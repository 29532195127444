import { defineComponent, ref, onMounted, reactive, toRefs } from "vue";
import { getConfig } from "@/http/home";
import { getGeneralInfo } from "@/http/about";
import { imgUrl } from "@/utils/urlManage";
import { INTERFACE_TYPE_ENUM } from "@/utils/constant";
const _window = window;
export default defineComponent({
  setup() {
    const _this = reactive({
      name: "",
      address: "",
      tel: "",
      email: "",
      fileUrl: ""
    });

    const dom = ref();
    let map;
    const methods = {
      initMap() {
        // 创建地图实例
        map = new _window.BMap.Map(dom.value); // 创建地址解析器实例

        var myGeo = new _window.BMap.Geocoder();
        map.enableScrollWheelZoom(true); //滚轮缩放

        map.addControl(new _window.BMap.NavigationControl()); //平移缩放控件

        map.addControl(new _window.BMap.ScaleControl()); //比例尺

        map.addControl(new _window.BMap.OverviewMapControl()); //缩略地图
        // map.addControl(new _window.BMap.MapTypeControl()); //地图类型
        // 将地址解析结果显示在地图上，并调整地图视野
        // point地图坐标

        myGeo.getPoint(_this.address, function (point) {
          if (point) {
            // 设置中心点坐标和地图级别
            map.centerAndZoom(point, 16); // 创建标注

            var marker = new _window.BMap.Marker(point);
            map.addOverlay(marker);
            var content = `<table class="mapTable">`;
            content = content + `<tr><th class="marker"> ${_this.name}</th></tr>`;
            content = content + `<tr><td> 地址：${_this.address}</td></tr>`;
            content = content + `<tr><td> 电话：${_this.tel}</td></tr>`;
            content += "</table>";
            var infowindow = new _window.BMap.InfoWindow(content);
            marker.openInfoWindow(infowindow);
          }
        });
      },

      /**
       * @description: 获取机构信息
       * @return {*}
       * @author: pudding
       */
      getProductBarMethod() {
        getConfig({
          orgCode: sessionStorage.getItem("orgCode") || ""
        }).then(res => {
          if (res.data.code == 200) {
            console.log("898989898", res.data);
            _this.name = res.data.data.companyName;
            _this.address = res.data.data.companyAddress;
            _this.tel = res.data.data.contactNumber;
            _this.email = res.data.data.email; // if (res.data.data.companyAddress) {
            //   methods.initMap();
            // }
          }
        });
      },

      /**
       * @description: 获取地图图片
       * @return {*}
       * @author: pudding
       */
      async getImage() {
        const res = await getGeneralInfo({
          orgCode: sessionStorage.getItem("orgCode") || "",
          typeCode: 3
        });

        if (res.data.code == INTERFACE_TYPE_ENUM.SUCCESS) {
          _this.fileUrl = res.data.data.imageUrl;
        }
      }

    };
    onMounted(() => {
      methods.getProductBarMethod();
      methods.getImage();
    });
    return {
      dom,
      ...methods,
      ...toRefs(_this),
      imgUrl
    };
  }

});