import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import { sendContactUs } from "@/http/service";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { getGeneralInfo } from "@/http/about";
import { INTERFACE_TYPE_ENUM } from "@/utils/constant";
import About from "./About.vue";
export default defineComponent({
  components: {
    About
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const ruleFormRef = ref();
    const ruleForm = reactive({
      name: "",
      email: "",
      phone: "",
      clientDemand: "",
      orgCode: sessionStorage.getItem("orgCode") || ""
    });
    const rules = reactive({
      name: [{
        required: true,
        message: "请输入姓名",
        trigger: "blur"
      }],
      email: [{
        required: true,
        message: "请输入邮箱",
        trigger: "blur"
      }, {
        pattern: /^.+@.+\..+$/,
        message: "邮箱格式不正确",
        trigger: "blur"
      }],
      phone: [{
        required: true,
        message: "请输入手机号",
        trigger: "blur"
      }, {
        pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
        message: "手机号格式不正确",
        trigger: "blur"
      }],
      clientDemand: [{
        required: true,
        message: "请输入具体描述",
        trigger: "blur"
      }]
    });

    const _this = reactive({
      companyData: {}
    });

    const methods = {
      async getData() {
        const res = await getGeneralInfo({
          orgCode: sessionStorage.getItem("orgCode") || "",
          typeCode: 3
        });

        if (res.data.code == INTERFACE_TYPE_ENUM.SUCCESS) {
          _this.companyData = res.data.data;
        } else {
          ElMessage.error(res.data.message);
        }
      },

      goOnePath() {
        if (route.query.typeCode == "link") {
          window.open(route.query.link, "_blank");
        } else if (route.query.typeCode == "defined") {
          router.push(route.query.url);
        } else {
          router.push(route.query.url + `?onlyName=${route.query.oldName}`);
        }
      }

    };

    const sendForm = async formEl => {
      if (!formEl) return;
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const res = await sendContactUs(ruleForm);

          if (res.data.success) {
            ElMessage.success("发送成功");
            ElMessage.success("发送成功");
            formEl.resetFields();
          } else {
            ElMessage.error(res.data.message);
            ElMessage.error(res.data.message);
          }
        } else {
          // 验证失败
          console.log("error submit!", fields);
        }
      });
    };

    onMounted(() => {
      methods.getData();
    });
    return {
      ruleFormRef,
      ruleForm,
      rules,
      sendForm,
      router,
      ...toRefs(_this),
      ...methods,
      route
    };
  }

});