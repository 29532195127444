import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-edad56a6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "about"
};
const _hoisted_2 = {
  id: "container",
  ref: "dom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("p", null, "电话：" + _toDisplayString(_ctx.tel), 513), [[_vShow, _ctx.tel]]), _withDirectives(_createElementVNode("p", null, "邮箱：" + _toDisplayString(_ctx.email), 513), [[_vShow, _ctx.email]]), _withDirectives(_createElementVNode("p", null, "地址：" + _toDisplayString(_ctx.address), 513), [[_vShow, _ctx.address]]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_image, {
    src: _ctx.imgUrl(_ctx.fileUrl),
    lazy: "",
    fit: "cover"
  }, null, 8, ["src"])], 512)]);
}